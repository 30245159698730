import React from 'react';
import { BooleanField, Button, ChipField, ShowButton, Filter, ImageField, TextInput, List, Datagrid, FunctionField, TextField, DateField, ArrayField, NumberField, WrapperField, SimpleList, SearchInput } from 'react-admin';
import { Grid, Icon, Typography, useMediaQuery, Card, CardContent } from '@mui/material';
import orderStatuses from '../data/orderStatuses.json'
import { green, grey, red, orange } from '@mui/material/colors';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

function statusColor(status){
    switch (status) {
        case 'completed':
            return green[300];
        case 'pending':
            return red[200];
        case 'failed':
            return red[300];
        case 'processing':
            return orange[200];
        default:
            return grey[50];
    }
}

const fieldStyle = (record) => ({
    backgroundColor: statusColor(record.status),
    borderRadius: 20,
    border: "0.1px solid white",
    color: statusColor(record.status),
});

const postRowStyle = (record) => ({
    backgroundColor: 'inherit',
    borderRadius: 20,
    padding: 2,
    margin: 2,
    '& .RaDatagridRow': {
        backgroundColor: 'secondary',
        borderRadius: 20,
        padding: 2,
        margin: 2,
    },
    '& .RaDatagridHeaderRow': {
        backgroundColor: 'inherit',
    },'& .RaDatagridHeaderCell': {
        backgroundColor: 'inherit',
    },
});

const DateHeader = () => (
    <Typography variant="h4">Tilauspvm.</Typography>
);

const OrderFilter =  props => (
    <Filter {...props} variant="outlined">
        <SearchInput id="search" label="Haku" source="q" disabled />
        <TextInput id="id" label="Tilausnumero" source="id" disabled />
    </Filter>
);

const OrderIdHeader = () => (
    <Typography variant="h4">Tilausnumero</Typography>
);


const OrderStatusHeader = () => (
    <Typography variant="h4">Tilauksen tila</Typography>
);

const NameHeader = () => (
    <Typography variant="h4">Nimi</Typography>
);

const OrderPriceHeader = () => (
    <Typography variant="h4">Hinta</Typography>
);

const SourceHeader = () => (
    <Typography variant="h4">Kauppa</Typography>
);

const PaymentMethodHeader = () => (
    <Typography variant="h4">Maksutapa</Typography>
);

const PaymentDateHeader = () => (
    <Typography variant="h4">Maksupvm.</Typography>
);

const ShippingMethodHeader = () => (
    <Typography variant="h4">Toimitustapa</Typography>
);

const shippingMethod = (record) => {
    const methodId = record ? record?.shipping_lines[0]?.method_id || '' : '';
    switch (methodId) {
        case 'local_pickup':
            return 'Nouto liikkeestä';
        case 'wcv_pro_vendor_shipping':
            return 'Postitus';
        default:
            return 'Postitus';
    }
}

const postRowSx = (record, index) => ({
    borderBottom: 0.1,
});    

export const OrderListView = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isPaid = (record) => record.date_paid !== null ? true : false;
    return (
        <List
        empty={true} 
        emptyWhileLoading
        sort={{ field: 'date_created', order: 'DESC' }}
        perPage={10} 
        filters={<OrderFilter />} 
        title="admin.kirjain.xyz :: Tilaukset" {...props}
        sx={{padding: 1, spacing: 1, marginTop: 1, marginBottom: 1, marginLeft: 1, marginRight: 1, border: "0.1px", borderRadius: "10px", boxShadow: "0px 0px 0.1px 0px #000000"}}
        >
        {isSmall ? (
        <Datagrid optimized
        bulkActionButtons={false}
        size="small"
        isRowSelectable={record => false }
        rowClick="edit" 
        rowStyle={postRowStyle}>
            <Card sx={{ alignItems: 'flex-start',  backgroundColor: 'white', border: 'inherit', padding: 0.1, margin: 0.1}}>
            
            <CardContent>
            <Grid container spacing={1} style={{alignContent: 'center', alignItems: 'flex-start'}}>
                <Grid item xs={6} sm={4}>
                    <OrderIdHeader/>
                    <ChipField source="id" label="Tilausnumero"/>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <DateHeader/>
                    <DateField label="Tilauspvm." source="date_created" />
                </Grid>
                <Grid item xs={6} sm={4}>
                <FunctionField
                label="Monikauppiastilaus"
                variant='outlined'
                style={{fieldStyle}}
                render={
                record => record && record.multiVendorOrder ? 'Monikauppiastilaus' : ''
                }
                            />
                    <BooleanField
                        style={{padding: 2, margin: 2}}
                        looseValue={true}
                        valueLabelTrue="Kyllä"
                        valueLabelFalse="Ei"
                        FalseIcon={Icon}
                        TrueIcon={PeopleAltTwoToneIcon}
                        label="Monikauppiastilaus"
                        source="multiVendorOrder" 
                    />
                </Grid>
            
                <Grid item xs={6} sm={4}>
                    <SourceHeader/>
                    <TextField variant='caption' label="Kauppa" source="source"/>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <PaymentMethodHeader/>
                    <TextField variant='caption' label="Maksutapa" source="payment_method_title"/>
                </Grid>
                <Grid item xs={6} style={{ ...fieldStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                    <ShippingMethodHeader sx={{ mt: -5 }}/>
                    <FunctionField
                        label="Toimitustapa"
                        variant='caption'
                        sx={{ mt: 5, ml: -13}}
                        render={
                         record => record ? `${shippingMethod(record)}` : 'Postitus'
                    }
                    />
                </Grid>
                    <Grid item xs={12} sm={4}>
                    <OrderStatusHeader/>
                    <FunctionField
                    label="Tila"
                    variant='outlined'
                    style={{fieldStyle}}
                    render={
                        record => record.status === 'completed' ? 
                        <Button variant="contained" color="success">Valmistunut</Button>: 
                        <Button variant="contained" color="warning">{`${orderStatuses.find(status => status.name === record.status).translated}`}</Button>
                        }
                                />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <NameHeader/>
                        <WrapperField addLabel label="Tilaaja">
                            <TextField source="billing.first_name"/><span> </span>
                            <TextField source="billing.last_name"/>
                        </WrapperField>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                    <OrderPriceHeader/>
                    <NumberField
                    label="Hinta"
                    source="total"
                    locales="fi-FI"
                    options={{ style: 'currency', currency: 'EUR' }}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    {isPaid ? ((
                    <div>
                    <PaymentDateHeader/>
                    <DateField label="Maksupvm." source="date_paid" />
                    </div>
                )) : ''}
                </Grid>
                
                <Grid item sm={12} xs={12} sx={{borderTop: 0.1, borderTopStyle: 'dashed'}}>
                <ArrayField label="Myydyt kirjat" source="line_items">
                    <SimpleList
                        
                        primaryText={record => (
                            <Typography variant="body2">
                                Nimi: {record.name}<span><br/></span>
                                Kirjailija: {record.author}<span><br/></span>
                                Hylly: {record.shelf}<span><br/></span>
                                Sidosasu: {record.binding}<span><br/></span>
                                Myyty määrä: {record.quantity}<span><br/></span>
                                <br/><img src={record.image.src} alt={record.name} style={{width: 50, alignItems: 'center', justifyContent: 'center', display: 'flex'}}/>
                            </Typography>
                        )}
                        rowSx={postRowSx}

                        />
                </ArrayField>
                </Grid>
                <Grid item xs={12} sm={4} >
                <Typography variant="h4">Tulostusnäkymä
                <ShowButton label="Tulostusnäkymä" icon={<LocalPrintshopIcon sx={{fontSize: "large"}} />}/>
                </Typography>
            </Grid>
            </Grid>
            </CardContent>
            </Card>
            </Datagrid>
            ) : (
        <Datagrid optimized
        bulkActionButtons={false}
        //size="small"
        isRowSelectable={record => false }
        rowClick="edit" 
        rowStyle={postRowStyle}>
            <Card sx={{ 
                alignItems: 'flex-start',
                backgroundColor: 'white',
                border: 'inherit',
                padding: 0.1,
                margin: 0.1
                }}>
            <CardContent>
            <Grid container spacing={1} >
                <Grid item xs={3} sm={3}>
                    <OrderIdHeader/>
                    <ChipField source="id" label="Tilausnumero"/>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <DateHeader/>
                    <DateField label="Tilauspvm." source="date_created" />
                </Grid>
                <Grid item xs={3} sm={3}>
                <FunctionField
                label="Monikauppiastilaus"
                variant='outlined'
                style={{fieldStyle}}
                render={
                record => record && record.multiVendorOrder ? 'Monikauppiastilaus' : ''
                }
                            />
                    <BooleanField
                        looseValue={true}
                        valueLabelTrue="Kyllä"
                        valueLabelFalse="Ei"
                        FalseIcon={Icon}
                        TrueIcon={PeopleAltTwoToneIcon}
                        label="Monikauppiastilaus"
                        source="multiVendorOrder" 
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1} >
                <Grid item xs={3} sm={3}>
                    <SourceHeader/>
                    <TextField variant='caption' label="Kauppa" source="source"/>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <PaymentMethodHeader/>
                    <TextField variant='caption' label="Maksutapa" source="payment_method_title"/>
                </Grid>
                <Grid item xs={3} sm={3}>
                    <ShippingMethodHeader/>
                    <FunctionField
                        label="Toimitustapa"
                        variant='caption'
                        //sx={{ mt: 5, ml: -13}}
                        render={
                         record => record ? `${shippingMethod(record)}` : 'Postitus'
                    }
                                    />
                </Grid>
            </Grid>
      <Grid container spacing={1}>
            <Grid item xs={3} sm={3}>
                <OrderStatusHeader/>
                <FunctionField
                label="Tila"
                variant='outlined'
                style={{fieldStyle}}
                render={
                record => record.status === 'completed' ? 
                <Button variant="contained" color="success">Valmistunut</Button>: 
                <Button variant="contained" color="warning">
                    {orderStatuses.find(status => status.name === record.status).translated}
                    </Button>
            }
                            />
            </Grid>
            <Grid item xs={3} sm={3}>
                    <NameHeader/>
                    <WrapperField addLabel label="Tilaaja">
                        <TextField source="billing.first_name"/><span> </span>
                        <TextField source="billing.last_name"/>
                    </WrapperField>
            </Grid>
            <Grid item xs={3} sm={3} >
                <OrderPriceHeader/>
                <NumberField
                label="Hinta"
                source="total"
                locales="fi-FI"
                options={{ style: 'currency', currency: 'EUR' }}/>
            </Grid>
            <Grid item xs={3} sm={3}>
            {isPaid ? (
                        <div>
                        <PaymentDateHeader/>
                        <DateField label="Maksupvm." source="date_paid" />
                        </div>
                ) : ''}
                </Grid>
            </Grid>
            <Grid container spacing={1}>  
            <Grid item >
                <ArrayField label="Myydyt kirjat" source="line_items">
                    <Datagrid optimized
                    bulkActionButtons={false}
                    isRowSelectable={record => false }
                    sx={{'& .RaDatagrid-headerCell': { backgroundColor: 'inherit',},justifyContent: 'left', alignContent: 'left'}} >
                        <ImageField source="image.src" label={false}/>
                        <TextField label="Kirjan id" source="product_id" />
                        <TextField label="Kirjan nimi" source="name" />
                        <TextField label="Tekijä" source="author"/>
                        <TextField label="Sidosasu" source="binding"/>
                        <TextField label="Hylly" source="shelf"/>
                        <TextField label="Myyty määrä" source="quantity" />
                    </Datagrid>
                </ArrayField>
            </Grid>
        
      </Grid>
      <Grid item >
                <ShowButton label="Tulosta" icon={<LocalPrintshopIcon sx={{fontSize: "large"}} />}/>
            </Grid>
            </CardContent>
            </Card>
      </Datagrid>
        )}
    </List>
  );
}