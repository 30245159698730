import {  FirebaseAuthProvider } from "react-admin-firebase";
import { firebaseConfig as config } from '../FIREBASE_CONFIG';

const authProvider = FirebaseAuthProvider(config);


const vendors = require('../config/vendors.json');

/*function getDate() {
    return new Date().toISOString().slice(0, 10);
}*/


export async function getFolder(resource, action, targetId) {
    const user  = await authProvider.checkAuth();
    const vendorUid = user.uid;
    const userName = user?.displayName?.replace(/\s+/g, '-').toLowerCase() || user?.email?.split('@')[0].replace('.', '-').toLowerCase();
    let vendor = vendors[vendorUid]?.name;
    if (Array.isArray(vendor)) {
        vendor = vendor[0]?.name || vendor[0];
    }
    console.log("Vendor: " + vendor);
    //const date = getDate();
    switch (action) {
        case 'getList':
            if (resource === 'products'){
                return `products/kirjapino/${vendor}`;
            } else if (resource === 'orders') {
                return `kirjapino/orders/${vendor}`;
            } else if (resource === 'batch') {
                return `batch/${userName}/`;
            }  else if (resource === 'reports/batch') {
                return `reports/batch/${vendor}/`;
            }
            break;
        case 'getOne':
        case 'update':
            if (resource === 'products'){
                return `products/kirjapino/${vendor}/${targetId}`;
            } else if (resource === 'orders') {
                return `kirjapino/orders/${vendor}/${targetId}`;
            } else if (resource === 'batch') {
                return `batch/${userName}/${targetId}`;
            } 
            break;
        case 'create':
            if (resource === 'products'){
                return `products/kirjapino/${vendor}`;
            } else if (resource === 'orders') {
                return `kirjapino/orders/${vendor}`;
            } else if (resource === 'batch') {
                return `batch/${userName}/${targetId}`;
            } 
            break;
        case 'delete':
            if (resource === 'products'){
                return `products/kirjapino/${vendor}`;
            } else if (resource === 'orders') {
                return `kirjapino/orders/${vendor}`;
            } else if (resource === 'batch') {
                return `batch/${userName}/${targetId}`;
            }
            break;
        default:
            return `products/kirjapino/${vendor}`;;
    }

}